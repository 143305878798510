import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "../svg/logo.svg"

const Header = ({ siteTitle }) => (
  <header className="border-b-4 border-primary bg-white">
    <div className="container h-16 sm:h-20 flex justify-between items-center">
      <Link
        to="/"
        className="text-primary hover:opacity-75 transition-opacity duration-200 ease-in inline-flex items-center"
        title={siteTitle}
      >
        <Logo className="h-12 w-auto block fill-current sm:h-16" />
        <span style={{ top: 2 }} className="ml-4 relative leading-none uppercase font-bold text-2xl w-24">{siteTitle}</span>
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
